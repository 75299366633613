import React from 'react';
// Types could be ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']

const en = {
	// Error Message Example
	// '401': { type: 'error', text: 'You are not able to login due to Unauthorized Access.' },
	'401': { type: 'danger', text: 'Your session has expired, please login again' },

	// Classification Update
	'0001': { type: 'success', text: 'Member Classification Updated Successfully.' },
	'0002': { type: 'danger', text: 'Member Classification Update Unsuccessful.' },

	// Official Vote Update
	'0003': { type: 'success', text: 'Member Offiial Vote Updated Successfully.' },
	'0004': { type: 'danger', text: 'Member Offiial Vote Update Unsuccessful.' },

	// Dates Error
	'1001': { type: 'danger', text: 'Incorrect input' },
	'1002': { type: 'danger', text: "'From' date cannot be greater than 'To' date." },
	'1003': { type: 'danger', text: "'From' date cannot be greater than the current date." },
	'10010': { type: 'danger', text: "Please enter a 'Meeting Date'." },
	'10011': { type: 'danger', text: "'Start Date' cannot be greater than End Date." },
	'10012': { type: 'danger', text: "'Start Date' cannot be greater than current date." },
	'10013': { type: 'danger', text: "'Start Date' and 'End Date' is mandatory to download 'New Members', 'Members Changing Employment' and 'Members Removed from Roster' reports." },
	'10014': { type: 'danger', text: "Please select at least one report." },

	// Member card details update
	'2001': { type: 'success', text: 'All changes saved successfully' },

	// No data from API
	'4001': { type: 'danger', text: 'No Data Found' },
	'4002': { type: 'danger', text: 'No InActivity Report found for the selected Committee' },
	'4003': { type: 'danger', text: 'There seems to be a momentary issue. Please refresh or try again after sometime.' },
	'4004': { type: 'danger', text: (<>We are facing an issue while placing the order. Please wait a few minutes and try to place the order again. If you still face the issue, you can reach out to <a href="mailto:support@astm.org" className="astm-link">support@astm.org</a>.</>)},

	// General Error Message
	'7017': { type: 'danger', text: 'Something went wrong. Please try again.' },
	'7018': { type: 'danger', text: 'Account number does not exist.' },
	'7019': { type: 'warning', text: 'Document not found.' },
	'7020': { type: 'warning', text: 'Member has no active association with the committee for which the document link is present.' },

	// ChangeOfEmployment
	'130001': { type: 'success', text: 'Thank you. Your request has been submitted.' },

	//Minutes and Agenda
	'50045': { type: 'danger', text: 'Select the Main committee or Sub-committee for which you want to Submit the Minutes or Agenda.' },
	'50046': { type: 'danger', text: 'File format not supported (Word, PDF or ZIP file only).' },
	'50047': { type: 'danger', text: 'Choose document type.' },
	'50048': { type: 'danger', text: 'File Size is too large not exceed 100 MB.' },

	//Membership Application Form
	'membership_form': {
		'validationMessages': {
			'required': 'Required Field.',
			'invalidNumber': 'Enter valid phone number.',
			'invalidEmail': 'Enter valid Email.',
			'invalidZipCode': 'Enter valid Zip/Postal Code.',
			'requiredVolumeAgree': 'Please select the above checkbox to indicate that you have read and agree to the ASTM IP Policy.'
		}
	},

	// Virtual Meetings errors and messages
	'70501': { type: 'danger', text: 'Please select a Meeting to pre-populate details.' },
	'70502': { type: 'danger', text: 'Please select a work item.' },
	'70505': { type: 'danger', text: 'Please enter the Topic for the meeting.' },
	'70506': { type: 'danger', text: 'Please select a Main Committee.' },
	'70507': { type: 'danger', text: 'Please select a Sub Committee.' },
	'70508': { type: 'danger', text: 'Please select a Meeting Type.' },
	'70510': { type: 'danger', text: 'Please select the Meeting Start Time.' },
	'70511': { type: 'danger', text: 'Please select the Recurrence Week.' },
	'70512': { type: 'danger', text: 'Please select the Meeting Start Date.' },
	'70513': { type: 'danger', text: 'Please select the Meeting End Date.' },
	'70514': { type: 'danger', text: 'Please select the Day(s) of the Week' },
	'70515': { type: 'danger', text: 'Meeting Start Date cannot be after Meeting End Date.' },
	'70516': { type: 'danger', text: 'Please select the Meeting Duration.' },
	'70517': { type: 'danger', text: 'Please select the Meeting Date.' },
	'70518': { type: 'danger', text: 'Please add at least one member or non-member to the invitees.' },
	'70519': { type: 'danger', text: 'Please enter a valid email.' },
	'70520': { type: 'danger', text: 'Please fill out all fields when adding a new non-member.' },
	'70521': { type: 'danger', text: 'Email already exists with another non-member in the same main committee.' },

	// Invitation
	'70601': { type: 'danger', text: "Please enter your professor's name." },
	'70602': { type: 'danger', text: "Please enter your professor's email address." },
	'70603': { type: 'danger', text: "Please enter a valid professor's email address." },
	'70604': { type: 'danger', text: "Please enter the student's name." },
	'70605': { type: 'danger', text: "Please enter the student's email address." },
	'70606': { type: 'danger', text: "Please enter a valid student's email address." },
	'70607': { type: 'danger', text: "Please enter your colleague's name." },
	'70608': { type: 'danger', text: "Please enter your colleague's email address." },
	'70609': { type: 'danger', text: "Please enter a valid colleague's email address." },
	'70610': { type: 'danger', text: "Please select a committee." },
	'70611': { type: 'danger', text: "You have chosen not to select any main committees during the upgrade process. You can choose to add committees and become a participating member at any time. Informational members do not receive the free volume benefit. Do you wish to continue?" },

	// Common errors
	'80001': { type: 'danger', text: 'No record found.' },
	'80002': { type: 'danger', text: 'File format not supported. Please try again.' },
	'80003': { type: 'danger', text: 'File size should not be greater than 7 MB. Please try again.' },
	'80004': { type: 'danger', text: 'Either the file type is not supported or you are trying to upload file having file size greater than 7 MB.' },

	//Student Screens Messages
	'90001': { type: 'warning', text: 'You have chosen to drop membership on all main committees during the upgrade process. Click next to proceed or cancel to revise your selection.' }
}

export default en;
